import {
  useLoaderData,
  useMatches,
  useFetcher,
  type Params,
} from "react-router";
import type { Resource, ServiceState } from "app/types/permissions";
import type { loader } from "app/routes/__vendor-portal";
import { DropdownSwitcher } from "~/components/DropdownSwitcher";
import { Inline, Text } from "~/components/vendorUI";
import { LoadingOverlay } from "~/components/Loader";
import { useTranslation } from "~/utils";

interface IMatches {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: HandleType;
}

type HandleType = {
  campaignType: "ppc" | "ppl";
  permission?:
    | "ppc"
    | "ppl"
    | `${"ppc" | "ppl"}_bidding`
    | `${"ppc" | "ppl"}_billing`
    | "ppl_leads"
    | "marketing_content"
    | "reviews"
    | "listings";
};

export const CampaignSwitcher = () => {
  const { session } = useLoaderData<typeof loader>();
  const matches = useMatches() as IMatches[];

  if (!("userId" in session && session.isLoggedIn)) return <></>;

  const campaignMatches = matches.filter(
    (match) =>
      match.handle &&
      typeof match.handle === "object" &&
      Object.prototype.hasOwnProperty.call(match.handle, "campaignType"),
  );

  if (campaignMatches.length === 0) return <></>;

  const match = campaignMatches.at(-1);

  const type = match?.handle?.campaignType;
  const permission = match?.handle?.permission;

  const resourcesType = permission || type;
  const campaigns = session.permissions?.[resourcesType!].resources;

  if (!campaigns?.length || campaigns?.length === 1) return <></>;

  return type === "ppl" ? (
    <PPLCampaignSwitcher resourcesType={resourcesType} />
  ) : (
    <PPCCampaignSwitcher resourcesType={resourcesType} />
  );
};

export const PPCCampaignSwitcher = ({
  showOverlayLoader = true,
  resourcesType = "ppc",
}: {
  showOverlayLoader?: boolean;
  resourcesType?: string;
}) => {
  const { session } = useLoaderData<{
    session: {
      permissions?: Record<string, ServiceState>;
      selectedPPCCampaign: Resource;
    };
  }>();
  const fetcher = useFetcher({ key: "ppc-campaign-switcher" });
  const { t } = useTranslation();

  const isRevalidating = fetcher.state !== "idle";
  const TRANSLATION_KEY_MAP: Record<number, string> = {
    0: t("CAMPAIGN-SWITCHER_ACTIVE-CAMPAIGNS_LABEL"),
    1: t("CAMPAIGN-SWITCHER_PAUSED-CAMPAIGNS_LABEL"),
    2: t("CAMPAIGN-SWITCHER_DRAFT-CAMPAIGNS_LABEL"),
  };

  const items = (session.permissions?.[resourcesType]?.resources || [])
    .filter(
      (res) =>
        res.status === "upgraded" ||
        res.status === "paused" ||
        res.status === "draft",
    )
    .map((item) => ({
      ...item,
      groupOrder:
        item.status === "paused" ? 1 : item.status === "draft" ? 2 : 0,
    }))
    .sort((i1, i2) => i1.groupOrder - i2.groupOrder)
    .map(({ groupOrder, ...item }) => ({
      ...item,
      groupName: TRANSLATION_KEY_MAP[groupOrder],
    }));
  if (items.length < 2) return <></>;
  const optimisticCampaign = items.find(
    ({ id }) => `${id}` === fetcher.formData?.get("id"),
  );

  return (
    <SwitcherWrapper>
      {isRevalidating && showOverlayLoader ? <LoadingOverlay /> : null}
      {items.length > 0 ? (
        <Text color="brand" weight="medium" size="14px">
          {t("CAMPAIGN-SWITCHER_CAMPAIGN_LABEL")}
        </Text>
      ) : null}
      <DropdownSwitcher
        items={items}
        groupByKey={"groupName"}
        selectedItem={optimisticCampaign || session.selectedPPCCampaign}
        onSelect={({ id }) => {
          fetcher.submit(
            { intent: "setSelectedPPCCampaign", id },
            { method: "POST", action: "/?index" },
          );
        }}
        contentAlign="start"
        by="id"
      />
    </SwitcherWrapper>
  );
};

export const PPLCampaignSwitcher = ({
  hideOverlay = false,
  resourcesType = "ppl",
}: {
  hideOverlay?: boolean;
  resourcesType?: string;
}) => {
  const { session } = useLoaderData<{
    session: {
      permissions?: Record<string, ServiceState>;
      selectedPPLCampaign: Resource;
    };
  }>();
  const { t } = useTranslation();
  const fetcher = useFetcher({ key: "ppl-campaign-switcher" });
  const isRevalidating = fetcher.state !== "idle";

  const items = (session.permissions?.[resourcesType]?.resources || []).sort(
    (resource1, resource2) => resource1.name.localeCompare(resource2.name),
  );
  const optimisticCampaign = items.find(
    ({ id }) => `${id}` === fetcher.formData?.get("id"),
  );
  if (items.length < 2) return <></>;
  return (
    <SwitcherWrapper>
      {isRevalidating && !hideOverlay ? <LoadingOverlay /> : null}
      {items.length > 0 ? (
        <Text color="brand" weight="medium" size="14px">
          {t("CAMPAIGN-SWITCHER_CAMPAIGN_LABEL")}
        </Text>
      ) : null}
      <DropdownSwitcher
        items={items}
        selectedItem={optimisticCampaign || session.selectedPPLCampaign}
        onSelect={({ id }) => {
          fetcher.submit(
            { intent: "setSelectedPPLCampaign", id },
            { method: "POST", action: "/?index" },
          );
        }}
        contentAlign="start"
        by="id"
      />
    </SwitcherWrapper>
  );
};

const SwitcherWrapper = ({ children }: { children: React.ReactNode }) => (
  <Inline align="center" distribute="end" gap="16px">
    {children}
  </Inline>
);
